import React from 'react'
import {
  FaRegCalendarAlt,
  FaRegClock,
  FaPhoneAlt,
  FaEnvelope,
  FaLocationArrow,
  FaInstagram,
  FaFacebook
} from 'react-icons/fa'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Section from '../Section'
import IconWrapper from './IconWrapper'

const mapsUrl = 'https://www.google.com/maps/place/Laska./@45.7687814,4.8355856,15z/data=!4m2!3m1!1s0x0:0xed50d445672c7050?sa=X&ved=2ahUKEwj0tdOE25b2AhXMiIsKHXTnDJoQ_BJ6BAg4EAU'

const Footer = () => (
  <Section id="contact" className="bg-light text-dark py-5" fluid>
    <Row className="p-5 text-center text-lg-left">
      <Col xs={12} lg={4} className="pb-5 pb-lg-0">
        <h5 className="font-bitter text-gold">Laska</h5>
        <div>
          <IconWrapper icon={FaRegCalendarAlt}>
            Mardi - Samedi
          </IconWrapper>
          <IconWrapper icon={FaRegClock}>
            Déjeuner & Diner
          </IconWrapper>
        </div>
      </Col>
      <Col xs={12} lg={4} className="pb-5 pb-lg-0">
        <h5 className="font-bitter text-gold">Contact</h5>
        <div>
          <IconWrapper icon={FaPhoneAlt} href="tel:+33478988630" isLink>
            +33 4 78 98 86 30
          </IconWrapper>
          <IconWrapper
            icon={FaEnvelope}
            href="mailto:info@laska-lyon.fr"
            isLink
          >
            info@laska-lyon.fr
          </IconWrapper>
          <IconWrapper
            icon={FaLocationArrow}
            href={mapsUrl}
            target="_blank"
            rel="noopener noreferrer"
            isLink
          >
            13 Rue Terraille, Lyon
          </IconWrapper>
        </div>
      </Col>
      <Col xs={12} lg={4}>
        <h5 className="font-bitter text-gold">Follow us</h5>
        <div>
          <IconWrapper
            icon={FaInstagram}
            href="https://www.instagram.com/laska.lyon/"
            target="_blank"
            rel="noopener noreferrer"
            isLink
          >
            Instagram
          </IconWrapper>
          <IconWrapper
            icon={FaFacebook}
            href="https://www.facebook.com/laska.lyon/"
            target="_blank"
            rel="noopener noreferrer"
            isLink
          >
            Facebook
          </IconWrapper>
        </div>
      </Col>
    </Row>
  </Section>
)

export default Footer
