import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import Footer from '../Footer'

import '../../styles/index.scss'

const Layout = ({ children }) => {
  if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]')
  }

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
