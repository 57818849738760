import React from 'react'
import Button from 'react-bootstrap/Button'

const ReservationButton = ({ ...props }) => (
  <Button
    href="/reservation"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    Réservation
  </Button>
)

export default ReservationButton
