import classNames from 'classnames'
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { FaBars } from 'react-icons/fa'
import Section from '../Section'

import * as styles from './index.module.scss'

import { ReactComponent as LaskaLogo } from './laska-logo.svg'
import ReservationButton from '../ReservationButton'

const Header = () => (
  <header>
    <Navbar
      expand="lg"
      bg="brown"
      fixed="top"
      className={classNames(styles.header, 'vw-100 py-3')}
      collapseOnSelect
    >
      <Section fluid>
        <Navbar.Brand href="/">
          <LaskaLogo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="mobile-nav" className="border-0">
          <FaBars className={styles.burgerIcon} />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="mobile-nav"
        >
          <Nav className="ml-auto text-center align-items-center">
            <Nav.Item>
              <Nav.Link href="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#about">
                About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#bon-cadeau">
                Bon cadeau
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#menu">
                Menu
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#contact">
                Contact
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-lg-3">
              <Nav.Link
                as={ReservationButton}
                href="/reservation"
              />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Section>
    </Navbar>
  </header>
)

export default Header
