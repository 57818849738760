import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const IconWrapper = ({
  className, icon: Icon, children, isLink, ...props
}) => {
  let content = (
    <>
      <Icon />
      <span className="pl-3">{children}</span>
    </>
  )

  if (isLink) {
    content = (
      <a className="link-darker" {...props}>
        {content}
      </a>
    )
  }

  return (
    <div
      className={
        classNames(
          'd-flex justify-content-center justify-content-lg-start align-items-center pt-2',
          className
        )
}
    >
      {content}
    </div>
  )
}

IconWrapper.defaultProps = {
  className: undefined,
  isLink: false
}

IconWrapper.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isLink: PropTypes.bool
}

export default IconWrapper
